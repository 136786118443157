*{
  padding: 0px;
  margin: 0px;
}
a{
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 0px;
}
html {
  scroll-behavior: smooth;
}
div {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}