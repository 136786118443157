.footer-section{
    height: auto;
    top: 60px;
    left: 0px;
    position: absolute;
    background-color: #E3E8FC;
}

.footer_banner{
    margin-top: 15%;
    height: 230px;
    background-color: #660db1;
}
.footer_banner img{
    position: absolute;
    top: -70%;
    left: 13%;
}
.footer_banner h1{
    padding-top: 50px;
    color: #fff;
    font-family: Roboto;
    font-weight: bold;
}
.footer_banner .hire{
    margin-top: 4%;
    height: 40px;
    width: 150px;
    border-radius: 30px;
    background-color: #e8542b;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    color: #fff;
    float: left;
}
.footer_banner .hire:hover{
    background-color: #EB7B6C;

}

.footer_banner .contact{
    margin-top: 4%;
    height: 40px;
    width: 150px;
    border-radius: 30px;
    border: 1px solid #fff;
    text-align: center;
    margin-left: 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    color: #fff;
    float: left;
}
.footer_banner .contact:hover{
    background-color: #e8542b;
    border: 1px solid #e8542b;
}


/* ######## footer section ######## */

.footer_section{
    height: auto;
    padding-bottom: 20px;
    background-color: #390479;
    padding-top: 5%;
}
.about_company{
    color: #fff;
    text-align: justify;
    line-height: 19px;
    word-spacing: -2px;
}
.social_media{
    margin-left: 25px;
}
.social_media i{
    margin-top: 40px;
    color: #fff;
    padding-left: 20px;
    font-size: 18px;
}

.fa-google:hover{
    color: #EB7B6C;
}
.fa-twitter:hover{
    color: rgb(29, 161, 242);
}
.fa-instagram:hover{
    color:  #fbad50;
}
.fa-linkedin:hover{
    color: #0077b5;
}



.footer_section h5{
    color: #fff;
    font-size: 20px;
    padding-bottom: 20px;
   
}
.footer_section ul li{
    text-decoration: none;
    list-style: none;
    color: #fff;
    font-size: 14px;
    margin-left: -20px;
    margin-top: 15px;
}



.footer .next_button{
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #EB7B6C;
    text-align: center;
    position: fixed;
    bottom: 40px;
    right: 30px;
    z-index: 99;
    background-color: #e3e8fc;
}
.footer .next_button:hover{
    background-color: #e8542b;
    color: #fff;
}









/* media Query */

@media only screen and (max-width: 600px) {
    .footer-section{
        height: auto;
    }
    .footer_banner{
        margin-top: 0%;
        height: auto;
        padding: 10px;
        padding-bottom: 20px;
    }
    .footer_banner img{
        position: none;
        top: 2%;
        left: -8%;
        display: none;
        height: 200px;
        width: 200px;
    }
    .footer_banner h1{
        padding-top: 0px;
        font-size: 30px;
        text-align: center;
    }
    .footer_banner .hire{
        height: 40px;
        width: 150px;
        border-radius: 30px;
        background-color: #e8542b;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 40px;
        color: #fff;
        float: none;
        margin: auto;
    }
    .footer_banner .contact{
        height: 40px;
        width: 150px;
        border-radius: 30px;
        border: 1px solid #fff;
        text-align: center;
        margin-left: 0px;
        font-size: 14px;
        font-weight: bold;
        line-height: 40px;
        color: #fff;
        float: none;
        margin: auto;
    }
    .footer_section{
        height: auto;
    }
    
    .footer .next_button{
        bottom: 60px;
        right: 10px;
        z-index: 99;
        background-color: #e3e8fc;
    }
    .footer_section h5{
        padding-top: 40px;
        padding-bottom: 10px;
    }
    .footer_section ul li{
        font-size: 14px;
        margin-left: -25px;
        margin-top: 15px;
    }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .footer_banner{
        margin-top: 15%;
        height: auto;
        padding: 10px;
        padding-bottom: 20px;
    }
    .footer_banner img{
        position: none;
        top: 2%;
        left: 7%;
        height: 250px;
        width: 250px;
    }
    .footer_section img{
        width: 100%;
    }
}