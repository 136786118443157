.about-section{
    height: auto;
    top: 60px;
    left: 0px;
    position: absolute;
    background-color: #E3E8FC;
}
 .about_title{
    text-align: center;
    font-family: Roboto;
    font-size: 40px;
    font-weight: 600;
    padding-top: 40px;
    padding-bottom: 10px;
    color: #1b364e;
}
.about_subtitle{
    color: #555969;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: lato;
    padding-bottom: 10px;
}
.about_content h3{
    font-size: 29px;
    font-weight: 700;
    color: #1b364e;
    padding-left: 20px;
}
.about-section p{
    padding-left: 20px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(31, 29, 29);
}
.about-section ul{
   list-style:none;
  }
.about-section li .fa-chevron-right{
  margin-right: 8px;
  color: #E8562A;
}
.about-section li {
  color: rgb(31, 29, 29);
  font-weight: 500;
  font-size: 14px;
}





/* our team */

.team_card{
    margin: auto;
}

.team_card .owl-dots{
    display: none;
}


.team_card .owl-carousel .owl-nav {
    overflow:visible;
    height: 0px;
  }

 .team_card .owl-theme .owl-dots .owl-dot.active span,
 .owl-theme .owl-dots .owl-dot:hover span {
    background: #5110E9;
  }


 .team_card .owl-carousel .item {
    text-align: center;
  }

 .team_card .owl-carousel .nav-button {
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 120px!important;
  }

 .team_card .owl-carousel .owl-prev.disabled,
  .owl-carousel .owl-next.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

 .team_card .owl-carousel .owl-prev {
    left: -10px!important;
  }

 .team_card .owl-carousel .owl-next {
    right: -10px!important;
  }

 .team_card .owl-theme .owl-nav [class*=owl-] {
    color: #FFFFFF;
    font-size: 39px;
    background-color: #99a7ff;
    border-radius: 3px;
    line-height: 22px;
  }


  .card_team img{
    height: 220px;
    /* width: 220px; */
  }
  
  .team_card .bottom_section h6{
    padding-top: 6px;
    font-size: 20px;
}
.team_card .bottom_section{
    font-size: 16px;
    padding-bottom: 6px;
    color: #1b364e;
    font-weight: 500;
}



  @media only screen and (max-width: 600px) {
    .about-section{
        height: auto;
        top: 60px;
        left: 0px;
        position: absolute;
        background-color: #E3E8FC;
    }
    .about_title{
        font-size: 30px;
    }
    .about_subtitle{
        font-size: 15px;
        text-align: justify;
    }
    .team_card .owl-carousel .nav-button{
        top: -55px!important;
    }
    .team_card .owl-carousel .owl-prev {
        left: 10px!important;
      }
    
     .team_card .owl-carousel .owl-next {
        right: 10px!important;
      }
  }