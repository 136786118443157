.employee_section{
    height: calc(100vh - 70px);
    top: 70px;
    left: 0px;
    position: absolute;
    background-color: #E3E8FC;
}
.employe .employe_title{
  margin-top: 50px;
    text-align: center;
    font-family: Roboto;
    font-size: 40px;
    font-weight: 600;
    padding-top: 40px;
    padding-bottom: 10px;
    color: #1b364e;
}
.employe_subtitle{
    color: #555969;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: lato;
    padding-bottom: 10px;
}

.employe .item {
    text-align: left!important;
}

.talent_card img{
    height: 40px!important;
    width: 40px!important;
}
.Developer_card h6{
    padding-top: 20px;
    font-size: 19px;
    font-family: lato;
    font-weight: 700;
    color: #1D4356;
}
.Developer_card p{
    font-size: 14px;
    font-weight: 600;
    color: #5C757F;
}





.employe .owl-dots{
    display: none;
}


.employe .owl-carousel .owl-nav {
    overflow:visible;
    height: 0px;
  }

 .employe .owl-theme .owl-dots .owl-dot.active span,
 .owl-theme .owl-dots .owl-dot:hover span {
    background: #5110E9;
  }


 .employe .owl-carousel .item {
    text-align: center;
  }

 .employe .owl-carousel .nav-button {
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 70px!important;
  }

 .employe .owl-carousel .owl-prev.disabled,
  .owl-carousel .owl-next.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

 .employe .owl-carousel .owl-prev {
    left: -10px!important;
  }

 .employe .owl-carousel .owl-next {
    right: -10px!important;
  }

 .employe .owl-theme .owl-nav [class*=owl-] {
    color: #FFFFFF;
    font-size: 39px;
    background-color: #99a7ff;
    border-radius: 3px;
    line-height: 22px;
  }


  .employe .register_button{
    margin: auto;
    height: 45px;
    width: 150px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    border-radius: 30px;
    background-color: #ff6f5c;
    text-align: center;
    line-height: 45px;
  }

.employe .register_button:hover{
  background-color: #e8542b;
}

.employe .now{
    text-decoration: none;
    color: #fff;
}


  .employee .next_button{
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #EB7B6C;
    text-align: center;
    position: fixed;
    bottom: 40px;
    right: 30px;
    z-index: 99;
    background-color: #e3e8fc!important;
}
.employee .next_button:hover{
    background-color: #e8542b!important;
    color: #fff;
}






/* ####### media Query ######## */

@media only screen and (max-width: 600px) {
    .employee_section{
        height: auto;
        top: 60px;
        left: 0px;
        position: absolute;
        background-color: #E3E8FC;
    }
    .employe .employe_title{
        font-size: 30px;
    }
    .employe_subtitle{
        font-size: 15px;
        text-align: justify;
    }
    .employe .next_button{
        bottom: 60px;
        right: 10px;
    }

    .employe .register_button{
      margin-bottom:30px;
    }

    .employe .owl-carousel .nav-button {
        height: 40px;
        width: 40px;
        cursor: pointer;
        position: absolute;
        top: -55px!important;
      }
    .employe .owl-carousel .owl-prev {
        left: 10px!important;
      }
    
     .employe .owl-carousel .owl-next {
        right: 200px!important;
      }
    
}


