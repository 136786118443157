.phoneLoginPage .row{
    background-color: white;
    padding: 70px 45px;
    text-align: center;
    margin: 10px 30px;
    
}

.phoneLoginPage .row .loginPageButton{
    padding: 5px 8px;
    background-color: rgba(0, 0, 255, 0.363);
    color: white;
    border: 0.1 px solid rgba(0, 0, 255, 0.363);
    border-radius: 10px;
    cursor: pointer;
}