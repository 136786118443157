input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.signup_section{
    height: auto;
    top: 60px;
    left: 0px;
    position: absolute;
    background-color: #E3E8FC;
}
.signup_page{
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 20px;
}
#errordomain{
    font-weight: bold;
}
#signuppage{
    display: none;
}

#alert2{
    position: fixed;
    top: 70px;
    right: 5px;
    z-index: 4444!important;
    display: none;
}

#alert2 p{
    padding: 10px 20px;
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border: 0.1px solid rgba(255, 0, 0, 0.5);
    border-radius: 10px;
}

.signup_page h5{
    text-align: center;
    font-size: 18px;
    padding-top: 4%;
    font-weight: 700;
    padding-bottom: 4%;
    color: #000!important;
}
.digibot{
    border-bottom: 2px solid rgb(252, 105, 105);
    padding-bottom: 4px;
}

a {
    text-decoration: none;
}


.signup_section .social-media .fb {
    background: #3b5998;
    color: #fff;
    width: 45%;
    float: left;
    
}
.signup_section .social-media .tw {
    background: #1da1f2;
    color: #fff;
    width: 45%;
    margin-left: 10px;
    float: left;
}

.signup_section .social-media a {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    background: #eee;
    border-radius: 5px;
    padding: 10px 12px;
}


.signup_page p{
    text-align: center;
    font-size: 17px;
    padding-top: 10px;
    font-weight: 600;
}

.domain_search_section{
    background-color: rgb(247, 86, 86);
}

.signup_page input[type=text],
.signup_page input[type=password],
.signup_page input[type=email],
.signup_page input[type=number] {
    height: 45px;
    width: 90%;
    background-color: #ffffff;
    border: 2px solid #e1e1e1;
    margin: 5px;
    font-size: 15px;
    border-radius: 5px;
    outline: none;
    padding-left: 20px;
}
.signup_page input:focus{
    border: 2px solid rgb(247, 119, 119);

}
.signup_page input[type=email]:focus{
    border: 2px solid rgb(247, 119, 119);
}
.signup_page input::placeholder{
    font-size: 14px;
}
.form-check{
    text-align: left;
}

.signup_page .remember{
    font-size: 14px;
    font-weight: 500;
   padding-left: 10px;
}

.signup_page .btn-style {
    font-size: 18px;
    color: #fff;
    width: 80%;
    background: rgb(247, 86, 86)!important;
    border: none;
    padding: 10px 15px;
    font-weight: 700;
    border-radius: 5px;
    transition: .3s ease;
}
.signup_page .already a {
    font-size: 15px;
    line-height: 25px;
    font-weight: 700;
    color: #464647;
    text-align: center;
}
.signup_page .already{
    font-size: 16px;
    color: #495057;
    font-weight: 400;
}



.signup_section .modal-content{
    background-color: #EB7B6C!important;
}
.signup_section .domain_search{
    height: 40px;
    width: 100%;
    border: none;
    outline: none;
    padding-left: 20px;
    background-color: #f5f5f5!important;
    border: 1px solid #EB7B6C!important;
}
.signup_section .domain_search_section ::placeholder{
    font-size: 12px;
}
.signup_section .domain_search_section h6{
    line-height: 40px;
    color: #fff!important;
}
.signup_section .srch-btn {
    border: 2px solid #fff;
    height: 40px;
    width: 100%;
    background-color: transparent;
    color: tomato!important;
    font-weight: 600;
    background-color: #fff!important;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 3px;
}
.signup_section .srch-btn:hover{
    background-color: #fff!important;
    color: #000!important;
}

.desc_resume p{
    font-size: 16px;
    font-weight: 600;
    color: #464647;
}


@media only screen and (max-width: 600px) {
    .signup_section{
        height: auto;
        top: 60px;
        left: 0px;
        position: absolute;
        background-color: #E3E8FC;
    }
    .signup_section .social-media a {
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        background: #eee;
        border-radius: 5px;
        padding: 8px 8px;
    }
}

.search_domain {
    width: 100%;
    margin: auto;
    background-color: #eb7b6c;
}

.my-facebook-button-class{
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 9px 10px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}
.my-facebook-button-class .fab.fa-facebook{
    color: #3b5998;
    padding-right: 7px;
    font-size: 20px;
}
.desc_resume{
    padding-bottom: 20px;
}

@media only screen and (max-width: 700px) {
    .my-facebook-button-class{
        padding: 10px 15px;
    }
    .desc_resume{
        padding-bottom: 100px;
    }
    .search_domain{
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .search_domain .srch-btn{
        margin-top: 7px;
    }
}
.mobile_menu #about .fas.fa-info-circle{
    color: black;
}
.phoneLoginPage .digibot{
    color: black;
}