.navbar{
    background-color: #E3E8FC;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99999;
    height: 70px;
}

.navbar-brand img{
    margin-left: 50px;
}

.fa-bars{
    color: rgb(204, 75, 75);
    padding: 10px;
    background-color: #E3E8FC;
}
.menu_button{
    display: none;
    border: none;
    outline: none;
}
#navbarNavDropdown{
    margin-left: 18%;
}
.nav-item{
    margin-right: 20px;
}
.nav-link{
    color: #0A1D50;
    font-family: lato;
    font-size: 16px;
   font-weight: lighter;
}

.nav-link:focus, .nav-link:hover {
    color: orangered;
}

a:hover {
    color: orangered;
}
 .login_button{
    margin-top: 0px;
    height: 35px;
    border-radius: 50px;
    width: 110px;
    text-align: center;
    background-color: #E3E8FC;

}


/* ############# mobile menu ############ */

.mobile_menu{
    height: 40px;
    width: 100%;
    display: none;
    background-color: #d1daff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 9999;
}
.bgc{
    color: #1b1c1d;
}

.home{
    width: 20%;
    height: 50px;
    line-height: 50px;
    float: left;
}

.about{
    width: 20%;
    height: 50px;
    line-height: 50px;
    float: left;
}
.contact{
    width: 20%;
    height: 50px;
    line-height: 50px;
    float: left;
}
.employee{
    width: 20%;
    height: 50px;
    line-height: 50px;
    float: left;
}
.login{
    width: 20%;
    height: 50px;
    line-height: 50px;
    float: left;
}
.social_media{
    display: none;
}

















/* media Query */

@media only screen and (max-width: 600px) {
    .navbar{
        background-color: #E3E8FC;
        width: 100%;
    }
    .navbar-brand img{
        width: 120px;
    }

    .social_media{
        float: left;
        margin-top: -30px;
        display: block;
        text-align: center;
    }
    .social_media li{
        display: inline-block;
    }
    .social_media .fab{
        color: #0A1D50;
        padding-left: 10px;
        font-size: 23px;
        
    }

    #navbarNavDropdown{
        margin-left: 0%;
    }
    .menu_button{
        display: none;
    }
    .navbar-brand img{
        margin-left: 10px;
    }


    /* ####### mobile menu ######## */

    .mobile_menu{
        height: 50px;
        width: 100%;
        background-color: #d1daff;
        position: fixed;
        bottom: 0px;
        right: 0px;
        display: block;
        z-index: 9999;
    }
}

@media only screen and (min-width: 700px) and (max-width: 768px) {
    .menu_button{
        display: block;
    }
    #navbarNavDropdown{
        margin-left: 0px;
    }
    .social_media{
        display: none;
    }
    .navbar-nav{
        background-color:#E3E8FC;  
    }
    
    
    .login_button{
        margin-top: 0px;
        height: 35px;
        margin-top: 10px;
        border-radius: 50px;
        width: 110px;
        text-align: center;
        background-color: #E3E8FC;
    }
    
}

@media only screen and (min-width: 780px) and (max-width: 1024px) {
    .navbar{
        background-color: #E3E8FC;
        width: 100%;
    }
    .social_media{
        display: none;
    }
    #navbarNavDropdown{
        margin-left: 7%;
    }
    .nav-item{
        margin-right: 15px;
    }
    .menu_button{
        display: none;
    }
    .mobile_menu{
        display: none;
    }
}
.navbar-nav .active .nav-link{
    color: orangered;
}