.why_us_section {
  height: calc(100vh - 70px);
  top: 70px;
  left: 0px;
  position: absolute;
  background-color: #e3e8fc;
}
.why_us .whyus_title {
  margin-top: 50px;
  font-family: Roboto;
  font-size: 50px;
  font-weight: 540;
  padding-top: 40px;
  padding-bottom: 10px;
  color: #1b364e;
}
.whyus_subtitle {
  color: #555969;
  font-size: 16px;
  font-weight: 600;
  font-family: lato;
  padding-bottom: 10px;
}

.why_us .whyus_title .rotate_me {
  display: inline-block;
  margin-left: 70px;
  transform-origin: 0 0;
  transform: rotate(90deg);
}
.why_us .whyus_title img {
  margin-left: -40px;
}

.accordion-item {
  color: rgb(29, 27, 27);
  cursor: pointer;
}

.accordion-button span {
  color: rgb(29, 27, 27);
}
.accordion-button {
  background-color: #e3e8fc;
}

.accordion-body {
  background-color: #e3e8fc;
}

.why_us .next_button {
  height: 40px;
  width: 40px;
  line-height: 40px;
  color: #eb7b6c;
  text-align: center;
  position: fixed;
  bottom: 40px;
  right: 30px;
  background-color: #e3e8fc;
}
.why_us .next_button:hover{
  background-color: #e8542b;
  color: #fff;
}


.why_us .prev_button {
  height: 40px;
  width: 40px;
  line-height: 40px;
  color: #eb7b6c;
  text-align: center;
  position: fixed;
  bottom: 100px;
  right: 30px;
  background-color: #e3e8fc;
}
.why_us .prev_button:hover{
  background-color: #e8542b;
  color: #fff;
}


/* media Query */

@media only screen and (max-width: 600px) {
  .why_us_section {
    height: calc(100vh - 100px);
    top: 70px;
    left: 0px;
    position: absolute;
    background-color: #e3e8fc;
  }

  .why_us .whyus_title {
    margin-top: 0px;
    font-size: 30px;
  }
  .whyus_subtitle {
    font-size: 15px;
  }
  .why_us .next_button {
    bottom: 60px;
    right: 10px;
  }
  .why_us .prev_button {
    bottom: 12 0px;
    right: 10px;
  }
}
