.talent-section{
    height: calc(100vh - 70px);
    top: 70px;
    left: 0px;
    position: absolute;
    background-color: #E3E8FC;
}
.talent .talent_title{
    margin-top: 50px;
    text-align: center;
    font-family: Roboto;
    font-size: 40px;
    font-weight: 600;
    padding-top: 40px;
    padding-bottom: 10px;
    color: #1b364e;
}
.talent_subtitle{
    color: #555969;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: lato;
    padding-bottom: 10px;
}


/* talent card css */


/* finance card */
.finance_card{
    height: auto;
    border-left: 2px solid #bacef6;
    margin: auto;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 50px;
}
.finance_card h4{
    padding-top: 15px;
    color: #135792;
    font-weight: 600;
}
.finance_card p{
    font-size: 14px;
    padding-top: 20px;
    font-weight: 600;
    color: #2f3031;
}
.finance_card h6{
    padding-top: 10px;
    color: #161a26;
}
.finance_card .finance_button{
    margin-bottom: 40px;
    margin-top: 30px;
    height: 45px;
    width: 180px;
    color: #6681d6;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #6681d6;
    text-align: center;
    line-height: 45px;
    border-radius: 30px;
}
.finance_card .finance_button:hover{
    background-color: orangered;
    color: #fff;
    border: 1px solid orangered;
 
}

/* Developers card */

.Developers_card{
    height: auto;
    border-left: 2px solid #bacef6;
    margin: auto;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 50px;
}


.Developers_card h4{
    padding-top: 15px;
    color: #135792;
    font-weight: 600;
}

.Developers_card p{
    font-size: 14px;
    padding-top: 20px;
    font-weight: 600;
    color: #2f3031;
}
.Developers_card h6{
    padding-top: 10px;
    color: #161a26;
}
.Developers_card .finance_button{
    margin-bottom: 40px;
    margin-top: 30px;
    height: 45px;
    width: 180px;
    color: #6681d6;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #6681d6;
    text-align: center;
    line-height: 45px;
    border-radius: 30px;
}
.Developers_card .finance_button:hover{
    background-color: orangered;
    color: #fff;
    border: 1px solid orangered;
}


/* Designers card */

.Designers_card{
    height: auto;
    border-left: 2px solid #bacef6;
    margin: auto;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 50px;
}
.Designers_card h4{
    padding-top: 15px;
    color: #135792;
    font-weight: 600;
}
.Designers_card p{
    font-size: 14px;
    padding-top: 20px;
    font-weight: 600;
    color: #2f3031;
}
.Designers_card h6{
    padding-top: 10px;
    color: #161a26;
}
.Designers_card .finance_button{
    margin-bottom: 40px;
    margin-top: 30px;
    height: 45px;
    width: 180px;
    color: #6681d6;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #6681d6;
    text-align: center;
    line-height: 45px;
    border-radius: 30px;
}
.Designers_card .finance_button:hover{
    background-color: orangered;
    color: #fff;
    border: 1px solid orangered;
}

.registertext{
    color: rgb(29, 29, 31);
    text-decoration: none;
    font-weight: 600;
}
.registertext:hover{
    color: #fff;
}



.talent .next_button{
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #EB7B6C;
    text-align: center;
    position: fixed;
    bottom: 40px;
    right: 30px;
    background-color: #E3E8FC;
}

.talent .next_button:hover{
    background-color: #e8562a;
    color: #fff;
}
.talent .prev_button:hover{
    background-color: #e8562a;
    color: #fff;
}


.talent .prev_button{
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #EB7B6C;
    text-align: center;
    position: fixed;
    bottom: 100px;
    right: 30px;
    background-color: #E3E8FC;
}


.finance_button:hover {
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes swing {
    20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
    }
    100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    }
    }
    @keyframes swing {
    20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
    }
    100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    }
    }






/* ####### media Query ######## */

@media only screen and (max-width: 700px) {
    .talent-section{
        height: auto;
    }
    .talent .talent_title{
        font-size: 33px;
    }
    .talent_subtitle{
        font-size: 15px;
        text-align: justify;
    }
    .finance_card{
        border-left: none;
        border-bottom: 2px solid #bacef6;
    }
    .Developers_card{
        border-left: none;
        border-bottom: 2px solid #bacef6;
    }
    .Designers_card{
        border-left: none;
        border-bottom: 2px solid #bacef6;
    }
    .talent .next_button{
        bottom: 60px;
        right: 10px;
    }
    .talent .prev_button{
        bottom: 120px;
        right: 10px;
    }
    
}