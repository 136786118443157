.contact_section{
    height: auto;
    top: 60px;
    left: 0px;
    position: absolute;
    background-color: #E3E8FC;
}

.contact_information h6{
    font-size: 15px;
    font-weight: 500;
    color: #5a5a5a;
    font-family: roboto,sans-serif;
    margin-bottom: 20px;
    text-align: left;
}
.bids_heading h2 {
    font-size: 30px;
    color: #242424;
    font-weight: 700;
    line-height: 1.2;
    font-family: roboto,sans-serif;
}
.line-shape1 {
    width: 100%;

}

.cinfo10 {
    margin-top: 30px;
    float: left;
}
.cinfo10 li {
    margin-left: -30px;
    display: block;
    margin-bottom: 15px;
}
.cinfo10 p {
    font-size: 16px;
    font-weight: 300;
    color: #666666;
    font-family: roboto,sans-serif;
    text-align: left;
    line-height: 24px;

}
.cinfo10 span {
    color: #242424;
    margin-right: 5px;
    font-weight: 500!important;
}
.cinfo10 i {
    color: #ff4500;
    margin-right: 8px;
    font-size: 20px;
}


/* contact from section */

.contact_form {
    float: left;
    width: 100%;
    padding: 50px 30px;
    background: #fff;
    border: 1px solid #e5e5e5;
}
.main-heading {
    text-align: center;
    margin-bottom: 9px;
}

.main-heading h2 {
    font-size: 24px;
    color: #242424;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 8px;
    font-family: roboto,sans-serif;
}

.contact_form .form-group {
    margin-top: 20px;
    float: left;
    width: 100%;
}
.contact_form input[type=email]{
    margin: 0px!important;
}
.contact_form input[type=email]:focus{
    margin: 0px!important;
    border: 2px solid rgb(37, 35, 35);
}

.contact_form .job-input {
    font-size: 14px;
    width: 100%;
    height: 40px;
    font-family: roboto,sans-serif;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    color: #242424;
    padding: 0 20px;
}
.label {
    font-size: 14px;
    font-family: roboto,sans-serif;
    font-weight: 500;
    color: #242424;
    margin-bottom: 10px;
    line-height: 24px;
}

.note-input {
    width: 100%;
    height: 100px;
    border-radius: 3px;
    padding: 15px;
    border: 1px solid #e5e5e5;
    color: #757575;
}

.withdraw_btn {
    font-size: 14px;
    font-weight: 500;
    font-family: roboto,sans-serif;
    text-transform: uppercase;
    color: #fff;
    background-color: rgb(49, 48, 48);
    border: 0;
    /* height: 50px;
    width: 20%; */
    padding: 8px 15px;
    margin-top: 30px;
    border-radius: 3px;
}
.withdraw_btn:hover{
    background: #ff4500;
}








@media only screen and (max-width: 600px) {
    .contact_section{
        height: auto;
        top: 60px;
        left: 0px;
        position: absolute;
        background-color: #E3E8FC;
    }
    .withdraw_btn{
        width: 100%;
    }
}