.login_section {
  height: calc(100vh - 70px);
  top: 70px;
  left: 0px;
  position: absolute;
  background-color: #e3e8fc;
}
.login_page {
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 20px;
}

.login_page h5 {
  text-align: center;
  font-size: 18px;
  padding-top: 5%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 550;
  padding-bottom: 2%;
}

.mobile_login {
  display: none;
}

.log_freelance {
  height: 40px;
  width: 45%;
  float: left;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background-color: orangered;
}
.log_employee {
  height: 40px;
  width: 45%;
  float: left;
  margin-left: 15px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background-color: #3b5998;
}
.log {
  text-decoration: none;
  color: #fff;
}
.log:hover {
  color: #fff;
}

a {
  text-decoration: none;
}

.login_section .social-media .fb {
  background: #3b5998;
  color: #fff;
  width: 45%;
  float: left;
}
.login_section .social-media .tw {
  background: #1da1f2;
  color: #fff;
  width: 45%;
  margin-left: 10px;
  float: left;
}

.login_section .social-media a {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  background: #eee;
  border-radius: 5px;
  padding: 10px 12px;
}

.login_page p {
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 600;
}

.login_page input[type="password"],
input[type="email"] {
  height: 45px;
  width: 90%;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  margin: 5px;
  border-radius: 30px;
  outline: none;
  padding-left: 20px;
}
.login_page input:focus {
  border: 2px solid rgb(247, 119, 119);
}
.login_page input[type="email"]:focus {
  border: 2px solid rgb(247, 119, 119);
}
.login_page input::placeholder {
  font-size: 14px;
}
.form-check {
  text-align: left;
}

.login_page .remember {
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
}

.login_page .btn-style {
  font-size: 18px;
  color: #fff;
  width: 80%;
  background: rgb(247, 86, 86);
  border: none;
  padding: 10px 15px;
  font-weight: 700;
  border-radius: 35px;
  transition: 0.3s ease;
}
.login_page .already a {
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
  color: #464647;
  text-align: center;
}
.login_page .already {
  font-size: 16px;
  color: #495057;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .login_section {
    height: calc(100vh - 100px);
    top: 60px;
    left: 0px;
    position: absolute;
    background-color: #e3e8fc;
  }
  .login_page {
    width: 90%;
    margin: auto;
    padding-bottom: 30px;
  }
  .login_section .social-media a {
    height: 30px;
    line-height: 16px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    background: #eee;
    border-radius: 5px;
    padding: 8px 8px;
  }
  .mobile_login {
    display: block;
  }
}

/* @media only screen and (max-width: 1024px) {
    .login_section{
        height: auto;
        top: 60px;
        left: 0px;
        position: absolute;
        background-color: #E3E8FC;
    }
} */
