.Testimonial-section{
    height: auto;
    top: 60px;
    left: 0px;
    position: absolute;
    background-color: #E3E8FC;
}
.testimonial_content{
    margin-top: 5%;
    background-image: url(./quote.svg);
    background-repeat: no-repeat;

}
.testimonial_button{
    margin-top: 10%;
}
.testimonial_content .testimonial_title{
    font-size: 35px;
    padding-left: 6%;
    font-weight: 500;
    color: #1b364e;
    font-family: Noto Serif KR;
}
.feedback_section{
    text-align: justify!important;
}
.feedback_section h6{
  color: rgb(32, 30, 30);
}

.feedback_section .cname{
  color: #262829;
  font-weight: 400;
}

/* .swipe-left{
    height: 30px;
    width: 30px;
    margin-top: 0%;
    text-align: center;
    line-height: 30px;
    background-color: #97def6;
    float: left;
    font-size: 13px;
    color: #fff;
}


.swipe-right{
    margin-top: 0%;
    height: 30px;
    width: 30px;
    font-size: 13px;
    text-align: center;
    line-height: 30px;
    margin-left: 4%;
    background-color: #2339d0;
    float: left;
    color: #fff;
} */


.testimonial_image img{
    height: 60px;
    width: 50px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
.feedback_content{
    font-size: 14px;
    font-weight: 600;
    color: rgb(49, 45, 45);
}


.testimonial .next_button{
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #EB7B6C;
    text-align: center;
    position: fixed;
    bottom: 40px;
    right: 30px;
    z-index: 99;
    background-color: #e3e8fc;
}

.testimonial .next_button:hover{
  background-color: #e8542b;
  color: #fff;
}


.Testimonial-section .owl-dots{
    display: none;
}


.owl-carousel .owl-nav {
    overflow: hidden;
    height: 0px;
  }

  .Testimonial-section .owl-theme .owl-dots .owl-dot.active span,
  .Testimonial-section .owl-theme .owl-dots .owl-dot:hover span {
    background: #8753ff;
  }


  .owl-carousel .item {
    text-align: center;
  }

  .owl-carousel .nav-button {
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: -110px !important;
  }

  .owl-carousel .owl-prev.disabled,
  .owl-carousel .owl-next.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  .owl-carousel .owl-prev {
    left: 600px;
  }

  .owl-carousel .owl-next {
    right: 80px;
  }

  .owl-theme .owl-nav [class*=owl-] {
    color: #FFFFFF;
    font-size: 39px;
    background-color: #a0abfd;
    border-radius: 3px;
    line-height: 22px;
  }

  /* .owl-carousel .prev-carousel:hover {
    background-position: 0px -53px;
  }

  .owl-carousel .next-carousel:hover {
    background-position: -24px -53px;
  } */


















@media only screen and (max-width: 600px){
   .testimonial_content .testimonial_title{
        font-size: 29px;
    }
    .swipe-left{
        margin-top: 10%;
    }
    .swipe-right{
        margin-top: 10%;
    }
  .testimonial .next_button{
    bottom: 60px;
    right: 10px;
  }
  .testimonial_image img{
    height: 50px!important;
    width: 50px!important;
    border-radius: 30px;
  }
  .owl-carousel .nav-button {
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: -70px !important;
  }
  .owl-carousel .owl-prev {
    left: 10px;
  }

  .owl-carousel .owl-next {
    right: 10px;
  }
  
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .owl-carousel .owl-prev {
        left: 550px;
      }
    
      .owl-carousel .owl-next {
        right: 10px;
      }
      .testimonial_image img{
        height: 35px;
        width: 35px;
        border-radius: 40px;
    }
}