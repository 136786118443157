.privacyPolicy{
    margin: 40px 0;
    padding: 40px 70px;
}
.privacyPolicy h1{
    margin: 20px 0;
    font-size: 30px;
    font-family: Poppins;
    color: rgb(36, 36, 36);
    font-weight: 400;
}
.privacyPolicy h2{
    padding-top: 5px;
    font-size: 25px;
    font-family: Poppins;
    color: rgb(36, 36, 36);
    font-weight: 400;
}
.privacyPolicy p{
    color: #535353;
    text-align: justify;
    font-family: Poppins;
    font-weight: 400;
    margin: 10px 0;
}
.privacyPolicy h6{
    font-family: Poppins;
    color: rgb(27, 27, 27);
    margin: 20px 0;
}
.privacyPolicy a{
    text-decoration: none;
    color: blue;
    word-break: break-all;
}
.privacyPolicy ul li{
    color: #535353;
    font-family: Poppins;
    font-weight: 300;
}
.privacyPolicy address {
    color: #535353;
    font-family: Poppins;
    font-weight: 300;
}




@media only screen and (max-width: 600px) {
    .privacyPolicy{
        margin: 0 0 0 0;
        padding: 20px 20px;
    }
}