input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.emplyeer_section{
    height: auto;
    top: 60px;
    left: 0px;
    position: absolute;
    background-color: #E3E8FC;
}
.emplyeer_page{
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 20px;
}

.emplyeer_page h5{
    text-align: center;
    font-size: 18px;
    padding-top: 4%;
    font-weight: 700;
    padding-bottom: 4%;
}
.digibot{
    border-bottom: 2px solid rgb(252, 105, 105);
    padding-bottom: 4px;
}

a {
    text-decoration: none;
}

.emplyeer_section .social-media .fb {
    background: #3b5998;
    color: #fff;
    width: 45%;
    float: left;
    
}
.emplyeer_section .social-media .tw {
    background: #1da1f2;
    color: #fff;
    width: 45%;
    margin-left: 10px;
    float: left;
}

.emplyeer_section .social-media a {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    background: #eee;
    border-radius: 5px;
    padding: 10px 12px;
}

.emplyeer_page p{
    text-align: center;
    font-size: 17px;
    padding-top: 10px;
    font-weight: 600;
}

.domain_search_section{
    background-color: rgb(247, 86, 86);
}
.emplyeer_page input[type=text],
.emplyeer_page input[type=password],
.emplyeer_page input[type=email],
.emplyeer_page input[type=number] {
    height: 44px;
    width: 90%;
    background-color: #ffffff;
    border: 2px solid #e1e1e1;
    margin: 5px;
    font-size: 15px;
    border-radius: 5px;
    outline: none;
    padding-left: 20px;
}
.emplyeer_page input:focus{
    border: 2px solid rgb(247, 119, 119);

}
.emplyeer_page input[type=email]:focus{
    border: 2px solid rgb(247, 119, 119);
    margin: 0px!important;
}
.emplyeer_page input::placeholder{
    font-size: 14px;
}
.form-check{
    text-align: left;
}

.emplyeer_page .remember{
    font-size: 14px;
    font-weight: 500;
   padding-left: 10px;
}

.emplyeer_page .btn-style {
    font-size: 16px;
    color: #fff;
    width: 100%;
    background: rgb(247, 86, 86);
    border: none;
    padding: 13px 18px;
    font-weight: 600;
    border-radius: 35px;
    transition: .3s ease;
}
.emplyeer_page .already a {
    font-size: 15px;
    line-height: 25px;
    font-weight: 700;
    color: #464647;
    text-align: center;
}
.emplyeer_page .already{
    font-size: 16px;
    color: #495057;
    font-weight: 400;
}


/* error text style code */
.error{
    margin: 0!important;
    padding: 0!important;
    text-align: left!important;
    padding-left: 20px!important;
    color: red!important;
    font-size: 12px!important;
}


#alert{
    position: fixed;
    top: 70px;
    right: 5px;
    z-index: 4444!important;
    display: none;
}

#alert p{
    padding: 10px 20px;
    background-color: rgba(0, 128, 0, 0.5);
    color: white;
    border: 0.1px solid rgba(0, 128, 0, 0.3);
    border-radius: 10px;
}

#alert2{
    position: fixed;
    top: 70px;
    right: 5px;
    z-index: 4444!important;
    display: none;
}

#alert2 p{
    padding: 10px 20px;
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border: 0.1px solid rgba(255, 0, 0, 0.5);
    border-radius: 10px;
}









@media only screen and (max-width: 600px) {
    .emplyeer_section{
        height: auto;
        top: 60px;
        left: 0px;
        position: absolute;
        background-color: #E3E8FC;
    }
    .emplyeer_section .social-media a {
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        background: #eee;
        border-radius: 5px;
        padding: 8px 8px;
    }
    .emplyeer_page .already{
        font-size: 14px;
        color: #495057;
        font-weight: 400;
    }
}