.home1 {
  height: calc(100vh - 70px);
  top: 70px;
  left: 0px;
  width: 100%;
  position: absolute;
  background-color: #e3e8fc;
}

/* heading_title */
.heading_title {
  margin-top: 50px;
  text-align: center;
  font-family: Rosarivo;
  font-size: 50px;
  letter-spacing: 3px;
  font-weight: 600;
  padding-top: 40px;
  text-shadow: 0px 3px 3px rgb(87, 82, 82);
}

/* arrow image */
.arrow_img {
  margin: auto;
}

/* heading subtitle */
.heading_subtitle {
  padding-top: 6px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 18px;
  font-weight: 550;
  color: rgba(0, 0, 0, 0.733);
}

.create_resume {
  height: 45px;
  width: 150px;
  border-radius: 30px;
  line-height: 45px;
  font-size: 13px;
  color: #fff;
  font-weight: 550;
 
  background-color: #e8562a;
  margin: auto;
  cursor: pointer;
}

.create_resume:hover{
  background-color: #eb7b6c;
}

.create_resume:hover {
  -webkit-animation-name: shake;
  animation-name: shake;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes shake {
  0%, 100% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
  }
  }
  @keyframes shake {
  0%, 100% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
  }
  }  





.arrow_img2 {
  margin-top: 20px;
  margin-left: 10px;
}

.register_count {
  margin-left: 10px;
  font-family: roboto;
  font-size: 18px;
  font-weight: 540;
}
.ellipse {
  text-align: left !important;
  padding-top: 30px;
}

.next_button {
  height: 40px;
  width: 40px;
  line-height: 40px;
  color: #e8562a;
  text-align: center;
  position: fixed;
  bottom: 40px;
  right: 30px;
  background-color: #e3e8fc;
  border-radius: 3px;
}
.next_button:hover{
  background-color: #e8562a;
  color: #fff;
 
}
/* 
.next_button {
  height: 40px;
  width: 40px;
  line-height: 40px;
  color: #eb7b6c;
  text-align: center;
  position: fixed;
  bottom: 40px;
  right: 30px;
  background-color: #e3e8fc;
} */

/* media Query */

@media screen and (max-width: 600px) {
  .home1 {
    height: calc(100vh - 100px);
    top: 70px;
    left: 0px;
    padding-bottom: 60px;
    background-color: #e3e8fc;
  }
  /* heading_title */
  .heading_title {
    font-size: 35px;
  }

  .heading_subtitle {
    text-align: center;
    font-size: 13px;
    padding: 10px;
  }

  .hero_img1,
  .hero_img2 {
    display: none;
  }

  .next_button {
    bottom: 60px;
    right: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .home1 {
    height: auto;
    top: 70px;
    left: 0px;
    padding-bottom: 40px;
    background-color: #e3e8fc;
  }
}
